import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { GridSize, SxProps, Theme } from '@mui/material';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useDesktop } from '../hooks/useDesktop';
import { coreThemeConstants } from '../theme/coreThemeConstants';
import type { PpWC } from '../types';
import { AppFooter } from './AppFooter';
import { AppNavbar } from './AppNavbar';

export type PpAppContainer = PpWC<{
  AppFooterSlot?: React.ReactElement;
  AppHeaderSlot?: React.ReactElement;
  BottomNavbarSlot?: React.ReactElement;
  SideNavbarSlot?: React.ReactElement;
  backgroundColor?: string;
  dataQa?: string;
  disableFooterFade?: boolean;
  fontColor?: string;
  footerBackgroundColor?: string;
  footerHasContent?: boolean;
  footerSolidFade?: boolean;
  fullHeight?: boolean;
  isVisible?: boolean;
  mainSxProps?: SxProps<Theme>;
  wideFooter?: boolean;
}>;

export function AppContainer({
  AppFooterSlot,
  AppHeaderSlot,
  backgroundColor,
  BottomNavbarSlot,
  children,
  dataQa,
  disableFooterFade,
  fontColor,
  footerBackgroundColor,
  footerHasContent,
  footerSolidFade,
  fullHeight,
  isVisible = true,
  mainSxProps,
  SideNavbarSlot,
  wideFooter,
}: PpAppContainer): React.ReactElement {
  const theme = useTheme();

  const isDesktop = useDesktop();
  const shouldDisplayBottomBar = (!isDesktop || !SideNavbarSlot) && BottomNavbarSlot;

  const styles = {
    container: css`
      min-height: 100%;
    `,
    innerContainer: css`
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      background-color: ${theme.palette.grayscale.offWhite};

      ${backgroundColor &&
      css`
        background: ${backgroundColor};
      `}

      ${fontColor &&
      css`
        color: ${fontColor};
      `}

      ${fullHeight &&
      css`
        height: 100%;
      `}
    `,
    main: css`
      padding-bottom: ${BottomNavbarSlot ? theme.spacingForNavbar : theme.spacing(8)};
      flex-grow: 1;
    `,
    sidebar: css`
      box-shadow: ${theme.shadows[2]};
      z-index: ${theme.zIndex.floatingBar};
    `,
  };

  const mainCss = [styles.container];
  if (!isVisible) {
    mainCss.push(visuallyHidden as SerializedStyles);
  }
  return (
    <Grid container aria-hidden={!isVisible} css={mainCss}>
      {isDesktop && SideNavbarSlot && (
        <Grid
          item
          css={styles.sidebar}
          /* eslint-disable react/jsx-sort-props */
          xs={false}
          sm={false}
          md={false}
          lg={coreThemeConstants.sidebarColumns as GridSize}
          /* eslint-enable react/jsx-sort-props */
        >
          {SideNavbarSlot}
        </Grid>
      )}
      <Grid
        item
        /* eslint-disable react/jsx-sort-props */
        xs={12}
        sm={12}
        md={12}
        lg={isDesktop && SideNavbarSlot ? 10 : 12}
        /* eslint-enable react/jsx-sort-props */
      >
        <div css={styles.innerContainer}>
          {AppHeaderSlot}
          <Box
            component="main"
            css={styles.main}
            data-qa={dataQa ? `${dataQa}-scene` : 'scene'}
            sx={mainSxProps}
          >
            {children}
          </Box>
          {AppFooterSlot && (
            <AppFooter
              backgroundColor={footerBackgroundColor || backgroundColor}
              disableFade={disableFooterFade}
              hasContent={footerHasContent}
              solidFade={footerSolidFade}
              wide={wideFooter}
            >
              {AppFooterSlot}
            </AppFooter>
          )}
          {shouldDisplayBottomBar && <AppNavbar>{BottomNavbarSlot}</AppNavbar>}
        </div>
      </Grid>
    </Grid>
  );
}
